import logo from './logo.png';
import './Home.css';

function App() {
  return (

    <div className="App">
        <div id ="Background">
            <div id="StartDiv">
                <img src={logo} className="App-logo" alt="logo" id="LogoBigHalfCentered"/>
                <h1 id="title">Welcome to af-comms</h1>
                <h2 id="sub-title">The antifurry website!</h2>
                <a href="/Home" target="_blank" rel="noreferrer" id="external-link-button-start">Home page</a>
                <a href="/Login" target="_blank" rel="noreferrer" id="external-link-button-start">Sign up! [beta]</a>
            </div>
        </div>
    </div>
  );
}

export default App;
