// Default imports
import logo from './logo.png';

import './Home.css';

// Auth related imports
import { Amplify } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css';
import { Hub } from 'aws-amplify/utils';
import { Alert, withAuthenticator } from "@aws-amplify/ui-react";
import { fetchAuthSession } from '@aws-amplify/auth';

// React related imports
import React, { useEffect, useState } from 'react';

// API relatded imports
import axios from 'axios';


// Config amplify
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: '58tbkit3gvfmf1ge7arobcluv5',
      userPoolId: 'us-east-1_PK5vU8eMG',
      loginWith: { // Optional
        oauth: {
          domain: 'abcdefghij1234567890-29051e27.auth.us-east-1.amazoncognito.com',
          scopes: ['profile aws.cognito.signin.user.admin'],
          redirectSignIn: ['http://localhost:3000/','https://example.com/'],
          redirectSignOut: ['http://localhost:3000/','https://example.com/'],
          responseType: 'code',
        },
        email:'true',
        preferred_username: {
          required: true
        },
        birthdate: 'true',
      },
    }
  }
});

// Login function
function Login({ signOut, user}) {
  // User Name Effect Thingy , also sets the rank and other stuff
  const [preferredUsername, setPreferredUsername] = useState('');
  const [rank, setrank]  = useState('');
  const [rankspan, setrankspan]  = useState('');

  // Quote stuff
  const [quote, setquote]  = useState('');
  const [quoteauthor, setquoteauthorspan] = useState('');

  useEffect(() => {

    const getPageData = async () => {

      const session = await fetchAuthSession();

      // Get user rank

      try{
        if (session.tokens.idToken.payload["cognito:groups"].includes("Owner")) {
          setPreferredUsername( <span id="owner-span">{session.tokens.idToken.payload["preferred_username"]}</span>);
          setrankspan(<span id="owner-span">Owner</span>)
          setrank("Owner")
        }
        else if (session.tokens.idToken.payload["cognito:groups"].includes("Admins")) {
          setPreferredUsername( <span id="admin-span">{session.tokens.idToken.payload["preferred_username"]}</span>);
          setrankspan(<span id="admin-span">Admin</span>)
          setrank("Admin")
        }
      }
      catch {
        setPreferredUsername( <span span id="member-span">{session.tokens.idToken.payload["preferred_username"]}</span>);
        setrankspan(<span id="member-span" >Member</span>)
        setrank("Member")
      }

      // Get quote data
      const QuoteResponse = await axios.get("https://kmcfdt3xye.execute-api.us-east-1.amazonaws.com/default/AntifurryQuotesApi-dev")
      setquote(QuoteResponse.data["content"])
      if (QuoteResponse.data["owner"] == true){
        setquoteauthorspan(<span id="owner-span">-{QuoteResponse.data["author"]}</span>)
      }
      else if (QuoteResponse.data["admin"] == true) {
        setquoteauthorspan(<span id="admin-span">-{QuoteResponse.data["author"]}</span>)
      }
      else {
        setquoteauthorspan(<span id="member-span">-{QuoteResponse.data["author"]}</span>)
      }
    };
  
    getPageData();
  }, []);

  function GetRankDescriptor(rank){
    if (rank === "Owner"){
      return <span>You are the proud owner of this website!</span>
    } else if (rank === "Admin"){
      return <span>You have admin powers and you have the ability to moderate users of this website.</span>
    } else if (rank === "Member"){
      return <span>You are a member of www.af-comms.net and hopefully you are a anti-furry or a neutral , if you are a furry , then you aren't Welcome.</span>
    }
  }

    // HTML JSX thingy
    return (
    <div className="App">
      <div id ="Background">
          <div id="division">
              <h1 id="title" >af-comms</h1>
              <h2 id="sub-title">Logged in</h2>
          </div>

          <div id="division">
              <h3 className='med-margin-relation'>You have logged in as user {preferredUsername}</h3>
              <p className='high-margin-relation'>You have {rankspan} rank!</p>
              <p className='high-margin-relation'>{GetRankDescriptor(rank)}</p>
              <p className='med-margin-relation'>This page/feature isnt used for anything yet but it will be used soon!</p>
          </div>

          <div id="division">
            <h3 className="med-margin-relation">Antifurry quote of the now:</h3>
            <h2 className="high-margin-relation">{quote}</h2>
            <h3 className="high-margin-relation">{quoteauthor}</h3>
          </div>

          <button onClick={signOut}>Sign out</button>
      </div>
    </div>
    );
}



export default withAuthenticator(Login);
  
